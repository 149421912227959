@font-face {
  font-family: "TeleGroteskNext-Ultra";
  src: url("./fonts/TeleGroteskNext/Desktop/telegrotesknext-ultra.ttf");
}

@font-face {
  font-family: "TeleGroteskNext";
  src: url("./fonts/TeleGroteskNext/Desktop/telegrotesknext-medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "TeleGroteskNext";
  src: url("./fonts/TeleGroteskNext/Desktop/telegrotesknext-bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "TeleGroteskNext";
  src: url("./fonts/TeleGroteskNext/Desktop/telegrotesknext-regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "TeleNeoOffice";
  src: url("./fonts/teleneo-office/tele-neo-office.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "TeleNeo";
  src: url("./fonts/teleneo-office/tele-neo.otf");
  font-weight: normal;
}
@font-face {
  font-family: "TeleNeoExtraBold";
  src: url("./fonts/teleneo-office/tele-neo-extrabold.otf");
  font-weight: normal;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./fonts/FontsFree-Net-SFProDisplay-Medium.ttf");
}

@font-face {
  font-family: "SFProText";
  src: url("./fonts/FontsFree-Net-SFProText-Regular.ttf");
}

@font-face {
  font-family: "Hind";
  src: url("./fonts/Hind-Regular.ttf");
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("./fonts/HelveticaNeue-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoBold";
  src: url("./fonts/Roboto-Bold.ttf");
}

@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";

body {
  margin: 0;
  padding: 0;
  font-family: TeleGroteskNext, BlinkMacSystemFont, -apple-system, "Segoe UI",
    Roboto, Oxygen, Ubuntu, "Helvetica Neue", Arial, sans-serif !important;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  height: 100vh;
}

body::-webkit-scrollbar {
  width: 0.4375rem;
}

body::-webkit-scrollbar-track {
  background: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 0.625rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
h1,
h3 {
  font-weight: 300;
}

.nav-links {
  color: white;
  /*margin: 1.5rem 1rem;*/
  cursor: pointer;
  height: 100%;
  border-radius: 0;
  padding: 1.8rem 3rem;
  font-size: 0.875rem;
  font-weight: 700;
}

.nav-links:last-of-type {
  margin-right: 1rem;
}

.nav-links:hover {
  background-color: var(--tmo-magenta) !important;
  color: #fff !important;
  border-radius: 0;
}

h1 {
  font-size: 3.8em;
  margin: 0;
  font-weight: 500;
}

/* FLEX */
.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-start {
  justify-content: start !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

/* CURSOR*/

.cursor-pointer {
  cursor: pointer;
}

/* COLORS*/
.dark-hot-pink {
  color: var(--tmo-magenta);
}

.white {
  color: #ffffff;
}

.text_area {
  flex-grow: 0;
  margin: 0rem 0rem 0rem 1.5rem;
  padding: 0.625rem;
  border: solid 0.0625rem #7c8089;
  background-color: #21232b;
  color: white;
}

.text_field {
  width: 32.9375rem;
  flex-grow: 0;
  margin: 0rem 1.5rem 0rem 0;
  width: 100%;
}

.text_field:last-of-type {
  margin-bottom: 2rem;
}

.multidoc-nav-scrollview {
  width: rem;
}

.sticky-nav {
  position: sticky;
  top: 0;
  align-self: flex-start;
}

.ul-classes.sticky-nav>ul>li>div+ul>div>div>li :hover {
  color: var(--tmo-magenta);
}

.ul-classes.sticky-nav>.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label {
  color: var(--tmo-magenta);
  font-family: TeleGroteskNext-Ultra;
}

.ul-classes>ul>li.MuiTreeItem-root {
  border-bottom: 0.0625rem solid rgba(217, 219, 223, 0.4);
}

.ul-classes>ul>li.MuiTreeItem-root>div.MuiTreeItem-content {
  /* flex-direction: row-reverse; */
}

.ul-classes>ul>li.Mui-expanded {
  /* background-color: rgba(63, 81, 181, 0.08); */
}

div.ul-classes>ul>li.Mui-selected>div.MuiTreeItem-content>div.MuiTreeItem-label {
  color: var(--tmo-magenta);
}

.MuiTreeItem-label:hover {
  background-color: transparent !important;
}

.MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
}

.MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus>.MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
}

.MuiCollapse-wrapper .MuiTreeItem-root:hover {
  background: none;
}

.ul-classes.sticky-nav>.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label>div {
  color: var(--tmo-magenta);
  font-family: TeleGroteskNext !important;
}

.MuiTreeItem-iconContainer svg {
  font-size: 1.25rem !important;
  fill: #4a4e57;
}

.grid2x2-inputwrapper {
  display: flex;
  flex-grow: 1;
  padding-bottom: 1rem;
  flex-basis: calc(50% - 2.5rem);
  justify-content: center;
  flex-direction: column;
}

.grid2x2-inputwrapper:nth-child(2n) {
  display: flex;
  flex-grow: 1;
  margin-left: 2rem;
  padding-bottom: 1rem;
  flex-basis: calc(50% - 2.5rem);
  justify-content: center;
  flex-direction: column;
}

.MuiTypography-body1 {
  font-family: TeleGroteskNext !important;
  font-weight: 500 !important;
}

.transition {
  transition: all 0.3s ease;
}

.magentaText {
  color: var(--tmo-magenta);
}

.MuiTab-textColorInherit.Mui-selected {
  color: var(--tmo-magenta);
}

.MuiTab-wrapper {
  font-family: TeleGroteskNext;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  /* color: #4b4d61; */
}

.close-icon {
  height: 1.5rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  opacity: 0.6;
  cursor: pointer;
}

.close-icon:hover {
  opacity: 1;
}

.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  position: relative;
  min-height: 0.0625rem;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.underlined-breadcrumb:hover {
  text-decoration: underline;
}

#spectaql {
  font-weight: normal;
}

#spectaql #docs {
  max-width: fit-content!important;
  margin-left: 0px!important;
}

#spectaql #sidebar {
  padding-top: 60px !important;
}

#spectaql #sidebar a {
  font-weight: 400;
}